// To parse this data:
//
//   import { Convert, Oscal } from "./file";
//
//   const oscal = Convert.toOscal(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.
export var Transport;
(function (Transport) {
    Transport["TCP"] = "TCP";
    Transport["UDP"] = "UDP";
})(Transport || (Transport = {}));
export var PurpleState;
(function (PurpleState) {
    PurpleState["DISPOSITION"] = "disposition";
    PurpleState["OPERATIONAL"] = "operational";
    PurpleState["OTHER"] = "other";
    PurpleState["UNDER_DEVELOPMENT"] = "under-development";
})(PurpleState || (PurpleState = {}));
export var PartyType;
(function (PartyType) {
    PartyType["ORGANIZATION"] = "organization";
    PartyType["PERSON"] = "person";
})(PartyType || (PartyType = {}));
export var TimeUnit;
(function (TimeUnit) {
    TimeUnit["DAYS"] = "days";
    TimeUnit["HOURS"] = "hours";
    TimeUnit["MINUTES"] = "minutes";
    TimeUnit["MONTHS"] = "months";
    TimeUnit["SECONDS"] = "seconds";
    TimeUnit["YEARS"] = "years";
})(TimeUnit || (TimeUnit = {}));
export var ActorType;
(function (ActorType) {
    ActorType["ASSESSMENT_PLATFORM"] = "assessment-platform";
    ActorType["PARTY"] = "party";
    ActorType["TOOL"] = "tool";
})(ActorType || (ActorType = {}));
export var ObjectiveStatusState;
(function (ObjectiveStatusState) {
    ObjectiveStatusState["NOT_SATISFIED"] = "not-satisfied";
    ObjectiveStatusState["SATISFIED"] = "satisfied";
})(ObjectiveStatusState || (ObjectiveStatusState = {}));
export var FindingTargetType;
(function (FindingTargetType) {
    FindingTargetType["OBJECTIVE_ID"] = "objective-id";
    FindingTargetType["STATEMENT_ID"] = "statement-id";
})(FindingTargetType || (FindingTargetType = {}));
export var ParameterCardinality;
(function (ParameterCardinality) {
    ParameterCardinality["ONE"] = "one";
    ParameterCardinality["ONE_OR_MORE"] = "one-or-more";
})(ParameterCardinality || (ParameterCardinality = {}));
export var IncludeContainedControlsWithControl;
(function (IncludeContainedControlsWithControl) {
    IncludeContainedControlsWithControl["NO"] = "no";
    IncludeContainedControlsWithControl["YES"] = "yes";
})(IncludeContainedControlsWithControl || (IncludeContainedControlsWithControl = {}));
export var CombinationMethod;
(function (CombinationMethod) {
    CombinationMethod["KEEP"] = "keep";
    CombinationMethod["MERGE"] = "merge";
    CombinationMethod["USE_FIRST"] = "use-first";
})(CombinationMethod || (CombinationMethod = {}));
export var Order;
(function (Order) {
    Order["ASCENDING"] = "ascending";
    Order["DESCENDING"] = "descending";
    Order["KEEP"] = "keep";
})(Order || (Order = {}));
export var Position;
(function (Position) {
    Position["AFTER"] = "after";
    Position["BEFORE"] = "before";
    Position["ENDING"] = "ending";
    Position["STARTING"] = "starting";
})(Position || (Position = {}));
export var FluffyState;
(function (FluffyState) {
    FluffyState["DISPOSITION"] = "disposition";
    FluffyState["OPERATIONAL"] = "operational";
    FluffyState["OTHER"] = "other";
    FluffyState["UNDER_DEVELOPMENT"] = "under-development";
    FluffyState["UNDER_MAJOR_MODIFICATION"] = "under-major-modification";
})(FluffyState || (FluffyState = {}));
// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
var Convert = /** @class */ (function () {
    function Convert() {
    }
    Convert.toOscal = function (json) {
        return cast(JSON.parse(json), r("Oscal"));
    };
    Convert.oscalToJson = function (value) {
        return JSON.stringify(uncast(value, r("Oscal")), null, 2);
    };
    return Convert;
}());
export { Convert };
function invalidValue(typ, val, key, parent) {
    if (parent === void 0) { parent = ''; }
    var prettyTyp = prettyTypeName(typ);
    var parentText = parent ? " on ".concat(parent) : '';
    var keyText = key ? " for key \"".concat(key, "\"") : '';
    throw Error("Invalid value".concat(keyText).concat(parentText, ". Expected ").concat(prettyTyp, " but got ").concat(JSON.stringify(val)));
}
function prettyTypeName(typ) {
    if (Array.isArray(typ)) {
        if (typ.length === 2 && typ[0] === undefined) {
            return "an optional ".concat(prettyTypeName(typ[1]));
        }
        else {
            return "one of [".concat(typ.map(function (a) { return prettyTypeName(a); }).join(", "), "]");
        }
    }
    else if (typeof typ === "object" && typ.literal !== undefined) {
        return typ.literal;
    }
    else {
        return typeof typ;
    }
}
function jsonToJSProps(typ) {
    if (typ.jsonToJS === undefined) {
        var map_1 = {};
        typ.props.forEach(function (p) { return map_1[p.json] = { key: p.js, typ: p.typ }; });
        typ.jsonToJS = map_1;
    }
    return typ.jsonToJS;
}
function jsToJSONProps(typ) {
    if (typ.jsToJSON === undefined) {
        var map_2 = {};
        typ.props.forEach(function (p) { return map_2[p.js] = { key: p.json, typ: p.typ }; });
        typ.jsToJSON = map_2;
    }
    return typ.jsToJSON;
}
function transform(val, typ, getProps, key, parent) {
    if (key === void 0) { key = ''; }
    if (parent === void 0) { parent = ''; }
    function transformPrimitive(typ, val) {
        if (typeof typ === typeof val)
            return val;
        return invalidValue(typ, val, key, parent);
    }
    function transformUnion(typs, val) {
        // val must validate against one typ in typs
        var l = typs.length;
        for (var i = 0; i < l; i++) {
            var typ_1 = typs[i];
            try {
                return transform(val, typ_1, getProps);
            }
            catch (_) { }
        }
        return invalidValue(typs, val, key, parent);
    }
    function transformEnum(cases, val) {
        if (cases.indexOf(val) !== -1)
            return val;
        return invalidValue(cases.map(function (a) { return l(a); }), val, key, parent);
    }
    function transformArray(typ, val) {
        // val must be an array with no invalid elements
        if (!Array.isArray(val))
            return invalidValue(l("array"), val, key, parent);
        return val.map(function (el) { return transform(el, typ, getProps); });
    }
    function transformDate(val) {
        if (val === null) {
            return null;
        }
        var d = new Date(val);
        if (isNaN(d.valueOf())) {
            return invalidValue(l("Date"), val, key, parent);
        }
        return d;
    }
    function transformObject(props, additional, val) {
        if (val === null || typeof val !== "object" || Array.isArray(val)) {
            return invalidValue(l(ref || "object"), val, key, parent);
        }
        var result = {};
        Object.getOwnPropertyNames(props).forEach(function (key) {
            var prop = props[key];
            var v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
            result[prop.key] = transform(v, prop.typ, getProps, key, ref);
        });
        Object.getOwnPropertyNames(val).forEach(function (key) {
            if (!Object.prototype.hasOwnProperty.call(props, key)) {
                result[key] = transform(val[key], additional, getProps, key, ref);
            }
        });
        return result;
    }
    if (typ === "any")
        return val;
    if (typ === null) {
        if (val === null)
            return val;
        return invalidValue(typ, val, key, parent);
    }
    if (typ === false)
        return invalidValue(typ, val, key, parent);
    var ref = undefined;
    while (typeof typ === "object" && typ.ref !== undefined) {
        ref = typ.ref;
        typ = typeMap[typ.ref];
    }
    if (Array.isArray(typ))
        return transformEnum(typ, val);
    if (typeof typ === "object") {
        return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
            : typ.hasOwnProperty("arrayItems") ? transformArray(typ.arrayItems, val)
                : typ.hasOwnProperty("props") ? transformObject(getProps(typ), typ.additional, val)
                    : invalidValue(typ, val, key, parent);
    }
    // Numbers can be parsed by Date but shouldn't be.
    if (typ === Date && typeof val !== "number")
        return transformDate(val);
    return transformPrimitive(typ, val);
}
function cast(val, typ) {
    return transform(val, typ, jsonToJSProps);
}
function uncast(val, typ) {
    return transform(val, typ, jsToJSONProps);
}
function l(typ) {
    return { literal: typ };
}
function a(typ) {
    return { arrayItems: typ };
}
function u() {
    var typs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        typs[_i] = arguments[_i];
    }
    return { unionMembers: typs };
}
function o(props, additional) {
    return { props: props, additional: additional };
}
function r(name) {
    return { ref: name };
}
var typeMap = {
    "Oscal": o([
        { json: "catalog", js: "catalog", typ: u(undefined, r("Catalog")) },
        { json: "profile", js: "profile", typ: u(undefined, r("Profile")) },
        { json: "component-definition", js: "component-definition", typ: u(undefined, r("ComponentDefinition")) },
        { json: "system-security-plan", js: "system-security-plan", typ: u(undefined, r("SystemSecurityPlanSSP")) },
        { json: "assessment-plan", js: "assessment-plan", typ: u(undefined, r("SecurityAssessmentPlanSAP")) },
        { json: "assessment-results", js: "assessment-results", typ: u(undefined, r("SecurityAssessmentResultsSAR")) },
        { json: "plan-of-action-and-milestones", js: "plan-of-action-and-milestones", typ: u(undefined, r("PlanOfActionAndMilestonesPOAM")) },
    ], false),
    "SecurityAssessmentPlanSAP": o([
        { json: "assessment-assets", js: "assessment-assets", typ: u(undefined, r("AssessmentAssets")) },
        { json: "assessment-subjects", js: "assessment-subjects", typ: u(undefined, a(r("SubjectOfAssessment"))) },
        { json: "back-matter", js: "back-matter", typ: u(undefined, r("BackMatter")) },
        { json: "import-ssp", js: "import-ssp", typ: r("ImportSystemSecurityPlan") },
        { json: "local-definitions", js: "local-definitions", typ: u(undefined, r("AssessmentPlanLocalDefinitions")) },
        { json: "metadata", js: "metadata", typ: r("PublicationMetadata") },
        { json: "reviewed-controls", js: "reviewed-controls", typ: r("ReviewedControlsAndControlObjectives") },
        { json: "tasks", js: "tasks", typ: u(undefined, a(r("Task"))) },
        { json: "terms-and-conditions", js: "terms-and-conditions", typ: u(undefined, r("AssessmentPlanTermsAndConditions")) },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "AssessmentAssets": o([
        { json: "assessment-platforms", js: "assessment-platforms", typ: a(r("AssessmentPlatform")) },
        { json: "components", js: "components", typ: u(undefined, a(r("AssessmentAssetsComponent"))) },
    ], false),
    "AssessmentPlatform": o([
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "title", js: "title", typ: u(undefined, "") },
        { json: "uses-components", js: "uses-components", typ: u(undefined, a(r("UsesComponent"))) },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "Link": o([
        { json: "href", js: "href", typ: "" },
        { json: "media-type", js: "media-type", typ: u(undefined, "") },
        { json: "rel", js: "rel", typ: u(undefined, "") },
        { json: "text", js: "text", typ: u(undefined, "") },
    ], false),
    "Property": o([
        { json: "class", js: "class", typ: u(undefined, "") },
        { json: "name", js: "name", typ: "" },
        { json: "ns", js: "ns", typ: u(undefined, "") },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "uuid", js: "uuid", typ: u(undefined, "") },
        { json: "value", js: "value", typ: "" },
    ], false),
    "UsesComponent": o([
        { json: "component-uuid", js: "component-uuid", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-parties", js: "responsible-parties", typ: u(undefined, a(r("ResponsibleParty"))) },
    ], false),
    "ResponsibleParty": o([
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "party-uuids", js: "party-uuids", typ: a("") },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "role-id", js: "role-id", typ: "" },
    ], false),
    "AssessmentAssetsComponent": o([
        { json: "description", js: "description", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "protocols", js: "protocols", typ: u(undefined, a(r("ServiceProtocolInformation"))) },
        { json: "purpose", js: "purpose", typ: u(undefined, "") },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-roles", js: "responsible-roles", typ: u(undefined, a(r("ResponsibleRole"))) },
        { json: "status", js: "status", typ: r("ComponentStatus") },
        { json: "title", js: "title", typ: "" },
        { json: "type", js: "type", typ: "" },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "ServiceProtocolInformation": o([
        { json: "name", js: "name", typ: "" },
        { json: "port-ranges", js: "port-ranges", typ: u(undefined, a(r("PortRange"))) },
        { json: "title", js: "title", typ: u(undefined, "") },
        { json: "uuid", js: "uuid", typ: u(undefined, "") },
    ], false),
    "PortRange": o([
        { json: "end", js: "end", typ: u(undefined, 0) },
        { json: "start", js: "start", typ: u(undefined, 0) },
        { json: "transport", js: "transport", typ: u(undefined, r("Transport")) },
    ], false),
    "ResponsibleRole": o([
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "party-uuids", js: "party-uuids", typ: u(undefined, a("")) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "role-id", js: "role-id", typ: "" },
    ], false),
    "ComponentStatus": o([
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "state", js: "state", typ: r("PurpleState") },
    ], false),
    "SubjectOfAssessment": o([
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "exclude-subjects", js: "exclude-subjects", typ: u(undefined, a(r("SelectAssessmentSubject"))) },
        { json: "include-all", js: "include-all", typ: u(undefined, r("IncludeAll")) },
        { json: "include-subjects", js: "include-subjects", typ: u(undefined, a(r("SelectAssessmentSubject"))) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "type", js: "type", typ: "" },
    ], false),
    "SelectAssessmentSubject": o([
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "subject-uuid", js: "subject-uuid", typ: "" },
        { json: "type", js: "type", typ: "" },
    ], false),
    "IncludeAll": o([], false),
    "BackMatter": o([
        { json: "resources", js: "resources", typ: u(undefined, a(r("Resource"))) },
    ], false),
    "Resource": o([
        { json: "base64", js: "base64", typ: u(undefined, r("Base64")) },
        { json: "citation", js: "citation", typ: u(undefined, r("Citation")) },
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "document-ids", js: "document-ids", typ: u(undefined, a(r("DocumentIdentifier"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "rlinks", js: "rlinks", typ: u(undefined, a(r("ResourceLink"))) },
        { json: "title", js: "title", typ: u(undefined, "") },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "Base64": o([
        { json: "filename", js: "filename", typ: u(undefined, "") },
        { json: "media-type", js: "media-type", typ: u(undefined, "") },
        { json: "value", js: "value", typ: "" },
    ], false),
    "Citation": o([
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "text", js: "text", typ: "" },
    ], false),
    "DocumentIdentifier": o([
        { json: "identifier", js: "identifier", typ: "" },
        { json: "scheme", js: "scheme", typ: u(undefined, "") },
    ], false),
    "ResourceLink": o([
        { json: "hashes", js: "hashes", typ: u(undefined, a(r("Hash"))) },
        { json: "href", js: "href", typ: "" },
        { json: "media-type", js: "media-type", typ: u(undefined, "") },
    ], false),
    "Hash": o([
        { json: "algorithm", js: "algorithm", typ: "" },
        { json: "value", js: "value", typ: "" },
    ], false),
    "ImportSystemSecurityPlan": o([
        { json: "href", js: "href", typ: "" },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
    ], false),
    "AssessmentPlanLocalDefinitions": o([
        { json: "activities", js: "activities", typ: u(undefined, a(r("Activity"))) },
        { json: "components", js: "components", typ: u(undefined, a(r("AssessmentAssetsComponent"))) },
        { json: "inventory-items", js: "inventory-items", typ: u(undefined, a(r("InventoryItem"))) },
        { json: "objectives-and-methods", js: "objectives-and-methods", typ: u(undefined, a(r("AssessmentSpecificControlObjective"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "users", js: "users", typ: u(undefined, a(r("SystemUser"))) },
    ], false),
    "Activity": o([
        { json: "description", js: "description", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "related-controls", js: "related-controls", typ: u(undefined, r("ReviewedControlsAndControlObjectives")) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-roles", js: "responsible-roles", typ: u(undefined, a(r("ResponsibleRole"))) },
        { json: "steps", js: "steps", typ: u(undefined, a(r("Step"))) },
        { json: "title", js: "title", typ: u(undefined, "") },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "ReviewedControlsAndControlObjectives": o([
        { json: "control-objective-selections", js: "control-objective-selections", typ: u(undefined, a(r("ReferencedControlObjectives"))) },
        { json: "control-selections", js: "control-selections", typ: a(r("AssessedControls")) },
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
    ], false),
    "ReferencedControlObjectives": o([
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "exclude-objectives", js: "exclude-objectives", typ: u(undefined, a(r("SelectObjective"))) },
        { json: "include-all", js: "include-all", typ: u(undefined, r("IncludeAll")) },
        { json: "include-objectives", js: "include-objectives", typ: u(undefined, a(r("SelectObjective"))) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
    ], false),
    "SelectObjective": o([
        { json: "objective-id", js: "objective-id", typ: "" },
    ], false),
    "AssessedControls": o([
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "exclude-controls", js: "exclude-controls", typ: u(undefined, a(r("SelectControl"))) },
        { json: "include-all", js: "include-all", typ: u(undefined, r("IncludeAll")) },
        { json: "include-controls", js: "include-controls", typ: u(undefined, a(r("SelectControl"))) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
    ], false),
    "SelectControl": o([
        { json: "control-id", js: "control-id", typ: "" },
        { json: "statement-ids", js: "statement-ids", typ: u(undefined, a("")) },
    ], false),
    "Step": o([
        { json: "description", js: "description", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-roles", js: "responsible-roles", typ: u(undefined, a(r("ResponsibleRole"))) },
        { json: "reviewed-controls", js: "reviewed-controls", typ: u(undefined, r("ReviewedControlsAndControlObjectives")) },
        { json: "title", js: "title", typ: u(undefined, "") },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "InventoryItem": o([
        { json: "description", js: "description", typ: "" },
        { json: "implemented-components", js: "implemented-components", typ: u(undefined, a(r("ImplementedComponent"))) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-parties", js: "responsible-parties", typ: u(undefined, a(r("ResponsibleParty"))) },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "ImplementedComponent": o([
        { json: "component-uuid", js: "component-uuid", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-parties", js: "responsible-parties", typ: u(undefined, a(r("ResponsibleParty"))) },
    ], false),
    "AssessmentSpecificControlObjective": o([
        { json: "control-id", js: "control-id", typ: "" },
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "parts", js: "parts", typ: a(r("Part")) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
    ], false),
    "Part": o([
        { json: "class", js: "class", typ: u(undefined, "") },
        { json: "id", js: "id", typ: u(undefined, "") },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "name", js: "name", typ: "" },
        { json: "ns", js: "ns", typ: u(undefined, "") },
        { json: "parts", js: "parts", typ: u(undefined, a(r("Part"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "prose", js: "prose", typ: u(undefined, "") },
        { json: "title", js: "title", typ: u(undefined, "") },
    ], false),
    "SystemUser": o([
        { json: "authorized-privileges", js: "authorized-privileges", typ: u(undefined, a(r("Privilege"))) },
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "role-ids", js: "role-ids", typ: u(undefined, a("")) },
        { json: "short-name", js: "short-name", typ: u(undefined, "") },
        { json: "title", js: "title", typ: u(undefined, "") },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "Privilege": o([
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "functions-performed", js: "functions-performed", typ: a("") },
        { json: "title", js: "title", typ: "" },
    ], false),
    "PublicationMetadata": o([
        { json: "document-ids", js: "document-ids", typ: u(undefined, a(r("DocumentIdentifier"))) },
        { json: "last-modified", js: "last-modified", typ: Date },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "locations", js: "locations", typ: u(undefined, a(r("Location"))) },
        { json: "oscal-version", js: "oscal-version", typ: "" },
        { json: "parties", js: "parties", typ: u(undefined, a(r("PartyOrganizationOrPerson"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "published", js: "published", typ: u(undefined, Date) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-parties", js: "responsible-parties", typ: u(undefined, a(r("ResponsibleParty"))) },
        { json: "revisions", js: "revisions", typ: u(undefined, a(r("RevisionHistoryEntry"))) },
        { json: "roles", js: "roles", typ: u(undefined, a(r("Role"))) },
        { json: "title", js: "title", typ: "" },
        { json: "version", js: "version", typ: "" },
    ], false),
    "Location": o([
        { json: "address", js: "address", typ: r("Address") },
        { json: "email-addresses", js: "email-addresses", typ: u(undefined, a("")) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "telephone-numbers", js: "telephone-numbers", typ: u(undefined, a(r("TelephoneNumber"))) },
        { json: "title", js: "title", typ: u(undefined, "") },
        { json: "urls", js: "urls", typ: u(undefined, a("")) },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "Address": o([
        { json: "addr-lines", js: "addr-lines", typ: u(undefined, a("")) },
        { json: "city", js: "city", typ: u(undefined, "") },
        { json: "country", js: "country", typ: u(undefined, "") },
        { json: "postal-code", js: "postal-code", typ: u(undefined, "") },
        { json: "state", js: "state", typ: u(undefined, "") },
        { json: "type", js: "type", typ: u(undefined, "") },
    ], false),
    "TelephoneNumber": o([
        { json: "number", js: "number", typ: "" },
        { json: "type", js: "type", typ: u(undefined, "") },
    ], false),
    "PartyOrganizationOrPerson": o([
        { json: "addresses", js: "addresses", typ: u(undefined, a(r("Address"))) },
        { json: "email-addresses", js: "email-addresses", typ: u(undefined, a("")) },
        { json: "external-ids", js: "external-ids", typ: u(undefined, a(r("PartyExternalIdentifier"))) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "location-uuids", js: "location-uuids", typ: u(undefined, a("")) },
        { json: "member-of-organizations", js: "member-of-organizations", typ: u(undefined, a("")) },
        { json: "name", js: "name", typ: u(undefined, "") },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "short-name", js: "short-name", typ: u(undefined, "") },
        { json: "telephone-numbers", js: "telephone-numbers", typ: u(undefined, a(r("TelephoneNumber"))) },
        { json: "type", js: "type", typ: r("PartyType") },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "PartyExternalIdentifier": o([
        { json: "id", js: "id", typ: "" },
        { json: "scheme", js: "scheme", typ: "" },
    ], false),
    "RevisionHistoryEntry": o([
        { json: "last-modified", js: "last-modified", typ: u(undefined, Date) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "oscal-version", js: "oscal-version", typ: u(undefined, "") },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "published", js: "published", typ: u(undefined, Date) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "title", js: "title", typ: u(undefined, "") },
        { json: "version", js: "version", typ: "" },
    ], false),
    "Role": o([
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "id", js: "id", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "short-name", js: "short-name", typ: u(undefined, "") },
        { json: "title", js: "title", typ: "" },
    ], false),
    "Task": o([
        { json: "associated-activities", js: "associated-activities", typ: u(undefined, a(r("AssociatedActivity"))) },
        { json: "dependencies", js: "dependencies", typ: u(undefined, a(r("TaskDependency"))) },
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-roles", js: "responsible-roles", typ: u(undefined, a(r("ResponsibleRole"))) },
        { json: "subjects", js: "subjects", typ: u(undefined, a(r("SubjectOfAssessment"))) },
        { json: "tasks", js: "tasks", typ: u(undefined, a(r("Task"))) },
        { json: "timing", js: "timing", typ: u(undefined, r("EventTiming")) },
        { json: "title", js: "title", typ: "" },
        { json: "type", js: "type", typ: "" },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "AssociatedActivity": o([
        { json: "activity-uuid", js: "activity-uuid", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-roles", js: "responsible-roles", typ: u(undefined, a(r("ResponsibleRole"))) },
        { json: "subjects", js: "subjects", typ: a(r("SubjectOfAssessment")) },
    ], false),
    "TaskDependency": o([
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "task-uuid", js: "task-uuid", typ: "" },
    ], false),
    "EventTiming": o([
        { json: "at-frequency", js: "at-frequency", typ: u(undefined, r("FrequencyCondition")) },
        { json: "on-date", js: "on-date", typ: u(undefined, r("OnDateCondition")) },
        { json: "within-date-range", js: "within-date-range", typ: u(undefined, r("OnDateRangeCondition")) },
    ], false),
    "FrequencyCondition": o([
        { json: "period", js: "period", typ: 0 },
        { json: "unit", js: "unit", typ: r("TimeUnit") },
    ], false),
    "OnDateCondition": o([
        { json: "date", js: "date", typ: Date },
    ], false),
    "OnDateRangeCondition": o([
        { json: "end", js: "end", typ: Date },
        { json: "start", js: "start", typ: Date },
    ], false),
    "AssessmentPlanTermsAndConditions": o([
        { json: "parts", js: "parts", typ: u(undefined, a(r("AssessmentPart"))) },
    ], false),
    "AssessmentPart": o([
        { json: "class", js: "class", typ: u(undefined, "") },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "name", js: "name", typ: "" },
        { json: "ns", js: "ns", typ: u(undefined, "") },
        { json: "parts", js: "parts", typ: u(undefined, a(r("AssessmentPart"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "prose", js: "prose", typ: u(undefined, "") },
        { json: "title", js: "title", typ: u(undefined, "") },
        { json: "uuid", js: "uuid", typ: u(undefined, "") },
    ], false),
    "SecurityAssessmentResultsSAR": o([
        { json: "back-matter", js: "back-matter", typ: u(undefined, r("BackMatter")) },
        { json: "import-ap", js: "import-ap", typ: r("ImportAssessmentPlan") },
        { json: "local-definitions", js: "local-definitions", typ: u(undefined, r("AssessmentResultsLocalDefinitions")) },
        { json: "metadata", js: "metadata", typ: r("PublicationMetadata") },
        { json: "results", js: "results", typ: a(r("AssessmentResult")) },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "ImportAssessmentPlan": o([
        { json: "href", js: "href", typ: "" },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
    ], false),
    "AssessmentResultsLocalDefinitions": o([
        { json: "activities", js: "activities", typ: u(undefined, a(r("Activity"))) },
        { json: "objectives-and-methods", js: "objectives-and-methods", typ: u(undefined, a(r("AssessmentSpecificControlObjective"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
    ], false),
    "AssessmentResult": o([
        { json: "assessment-log", js: "assessment-log", typ: u(undefined, r("AssessmentLog")) },
        { json: "attestations", js: "attestations", typ: u(undefined, a(r("AttestationStatements"))) },
        { json: "description", js: "description", typ: "" },
        { json: "end", js: "end", typ: u(undefined, Date) },
        { json: "findings", js: "findings", typ: u(undefined, a(r("Finding"))) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "local-definitions", js: "local-definitions", typ: u(undefined, r("ResultLocalDefinitions")) },
        { json: "observations", js: "observations", typ: u(undefined, a(r("Observation"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "reviewed-controls", js: "reviewed-controls", typ: r("ReviewedControlsAndControlObjectives") },
        { json: "risks", js: "risks", typ: u(undefined, a(r("IdentifiedRisk"))) },
        { json: "start", js: "start", typ: Date },
        { json: "title", js: "title", typ: "" },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "AssessmentLog": o([
        { json: "entries", js: "entries", typ: a(r("AssessmentLogEntry")) },
    ], false),
    "AssessmentLogEntry": o([
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "end", js: "end", typ: u(undefined, Date) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "logged-by", js: "logged-by", typ: u(undefined, a(r("LoggedBy"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "related-tasks", js: "related-tasks", typ: u(undefined, a(r("TaskReference"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "start", js: "start", typ: Date },
        { json: "title", js: "title", typ: u(undefined, "") },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "LoggedBy": o([
        { json: "party-uuid", js: "party-uuid", typ: "" },
        { json: "role-id", js: "role-id", typ: u(undefined, "") },
    ], false),
    "TaskReference": o([
        { json: "identified-subject", js: "identified-subject", typ: u(undefined, r("IdentifiedSubject")) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-parties", js: "responsible-parties", typ: u(undefined, a(r("ResponsibleParty"))) },
        { json: "subjects", js: "subjects", typ: u(undefined, a(r("SubjectOfAssessment"))) },
        { json: "task-uuid", js: "task-uuid", typ: "" },
    ], false),
    "IdentifiedSubject": o([
        { json: "subject-placeholder-uuid", js: "subject-placeholder-uuid", typ: "" },
        { json: "subjects", js: "subjects", typ: a(r("SubjectOfAssessment")) },
    ], false),
    "AttestationStatements": o([
        { json: "parts", js: "parts", typ: a(r("AssessmentPart")) },
        { json: "responsible-parties", js: "responsible-parties", typ: u(undefined, a(r("ResponsibleParty"))) },
    ], false),
    "Finding": o([
        { json: "description", js: "description", typ: "" },
        { json: "implementation-statement-uuid", js: "implementation-statement-uuid", typ: u(undefined, "") },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "origins", js: "origins", typ: u(undefined, a(r("FindingOrigin"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "related-observations", js: "related-observations", typ: u(undefined, a(r("FindingRelatedObservation"))) },
        { json: "related-risks", js: "related-risks", typ: u(undefined, a(r("FindingRelatedRisk"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "target", js: "target", typ: r("TargetClass") },
        { json: "title", js: "title", typ: "" },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "FindingOrigin": o([
        { json: "actors", js: "actors", typ: a(r("OriginatingActor")) },
        { json: "related-tasks", js: "related-tasks", typ: u(undefined, a(r("TaskReference"))) },
    ], false),
    "OriginatingActor": o([
        { json: "actor-uuid", js: "actor-uuid", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "role-id", js: "role-id", typ: u(undefined, "") },
        { json: "type", js: "type", typ: r("ActorType") },
    ], false),
    "FindingRelatedObservation": o([
        { json: "observation-uuid", js: "observation-uuid", typ: "" },
    ], false),
    "FindingRelatedRisk": o([
        { json: "risk-uuid", js: "risk-uuid", typ: "" },
    ], false),
    "TargetClass": o([
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "implementation-status", js: "implementation-status", typ: u(undefined, r("ImplementationStatus")) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "status", js: "status", typ: r("StatusClass") },
        { json: "target-id", js: "target-id", typ: "" },
        { json: "title", js: "title", typ: u(undefined, "") },
        { json: "type", js: "type", typ: r("FindingTargetType") },
    ], false),
    "ImplementationStatus": o([
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "state", js: "state", typ: "" },
    ], false),
    "StatusClass": o([
        { json: "reason", js: "reason", typ: u(undefined, "") },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "state", js: "state", typ: r("ObjectiveStatusState") },
    ], false),
    "ResultLocalDefinitions": o([
        { json: "assessment-assets", js: "assessment-assets", typ: u(undefined, r("AssessmentAssets")) },
        { json: "components", js: "components", typ: u(undefined, a(r("AssessmentAssetsComponent"))) },
        { json: "inventory-items", js: "inventory-items", typ: u(undefined, a(r("InventoryItem"))) },
        { json: "tasks", js: "tasks", typ: u(undefined, a(r("Task"))) },
        { json: "users", js: "users", typ: u(undefined, a(r("SystemUser"))) },
    ], false),
    "Observation": o([
        { json: "collected", js: "collected", typ: Date },
        { json: "description", js: "description", typ: "" },
        { json: "expires", js: "expires", typ: u(undefined, Date) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "methods", js: "methods", typ: a("") },
        { json: "origins", js: "origins", typ: u(undefined, a(r("FindingOrigin"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "relevant-evidence", js: "relevant-evidence", typ: u(undefined, a(r("RelevantEvidence"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "subjects", js: "subjects", typ: u(undefined, a(r("IdentifiesTheSubject"))) },
        { json: "title", js: "title", typ: u(undefined, "") },
        { json: "types", js: "types", typ: u(undefined, a("")) },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "RelevantEvidence": o([
        { json: "description", js: "description", typ: "" },
        { json: "href", js: "href", typ: u(undefined, "") },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
    ], false),
    "IdentifiesTheSubject": o([
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "subject-uuid", js: "subject-uuid", typ: "" },
        { json: "title", js: "title", typ: u(undefined, "") },
        { json: "type", js: "type", typ: "" },
    ], false),
    "IdentifiedRisk": o([
        { json: "characterizations", js: "characterizations", typ: u(undefined, a(r("Characterization"))) },
        { json: "deadline", js: "deadline", typ: u(undefined, Date) },
        { json: "description", js: "description", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "mitigating-factors", js: "mitigating-factors", typ: u(undefined, a(r("MitigatingFactor"))) },
        { json: "origins", js: "origins", typ: u(undefined, a(r("FindingOrigin"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "related-observations", js: "related-observations", typ: u(undefined, a(r("RiskRelatedObservation"))) },
        { json: "remediations", js: "remediations", typ: u(undefined, a(r("RiskResponse"))) },
        { json: "risk-log", js: "risk-log", typ: u(undefined, r("RiskLog")) },
        { json: "statement", js: "statement", typ: "" },
        { json: "status", js: "status", typ: "" },
        { json: "threat-ids", js: "threat-ids", typ: u(undefined, a(r("ThreatID"))) },
        { json: "title", js: "title", typ: "" },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "Characterization": o([
        { json: "facets", js: "facets", typ: a(r("Facet")) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "origin", js: "origin", typ: r("FindingOrigin") },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
    ], false),
    "Facet": o([
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "name", js: "name", typ: "" },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "system", js: "system", typ: "" },
        { json: "value", js: "value", typ: "" },
    ], false),
    "MitigatingFactor": o([
        { json: "description", js: "description", typ: "" },
        { json: "implementation-uuid", js: "implementation-uuid", typ: u(undefined, "") },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "subjects", js: "subjects", typ: u(undefined, a(r("IdentifiesTheSubject"))) },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "RiskRelatedObservation": o([
        { json: "observation-uuid", js: "observation-uuid", typ: "" },
    ], false),
    "RiskResponse": o([
        { json: "description", js: "description", typ: "" },
        { json: "lifecycle", js: "lifecycle", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "origins", js: "origins", typ: u(undefined, a(r("FindingOrigin"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "required-assets", js: "required-assets", typ: u(undefined, a(r("RequiredAsset"))) },
        { json: "tasks", js: "tasks", typ: u(undefined, a(r("Task"))) },
        { json: "title", js: "title", typ: "" },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "RequiredAsset": o([
        { json: "description", js: "description", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "subjects", js: "subjects", typ: u(undefined, a(r("IdentifiesTheSubject"))) },
        { json: "title", js: "title", typ: u(undefined, "") },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "RiskLog": o([
        { json: "entries", js: "entries", typ: a(r("RiskLogEntry")) },
    ], false),
    "RiskLogEntry": o([
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "end", js: "end", typ: u(undefined, Date) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "logged-by", js: "logged-by", typ: u(undefined, a(r("LoggedBy"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "related-responses", js: "related-responses", typ: u(undefined, a(r("RiskResponseReference"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "start", js: "start", typ: Date },
        { json: "status-change", js: "status-change", typ: u(undefined, "") },
        { json: "title", js: "title", typ: u(undefined, "") },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "RiskResponseReference": o([
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "related-tasks", js: "related-tasks", typ: u(undefined, a(r("TaskReference"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "response-uuid", js: "response-uuid", typ: "" },
    ], false),
    "ThreatID": o([
        { json: "href", js: "href", typ: u(undefined, "") },
        { json: "id", js: "id", typ: "" },
        { json: "system", js: "system", typ: "" },
    ], false),
    "Catalog": o([
        { json: "back-matter", js: "back-matter", typ: u(undefined, r("BackMatter")) },
        { json: "controls", js: "controls", typ: u(undefined, a(r("Control"))) },
        { json: "groups", js: "groups", typ: u(undefined, a(r("ControlGroup"))) },
        { json: "metadata", js: "metadata", typ: r("PublicationMetadata") },
        { json: "params", js: "params", typ: u(undefined, a(r("Parameter"))) },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "Control": o([
        { json: "class", js: "class", typ: u(undefined, "") },
        { json: "controls", js: "controls", typ: u(undefined, a(r("Control"))) },
        { json: "id", js: "id", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "params", js: "params", typ: u(undefined, a(r("Parameter"))) },
        { json: "parts", js: "parts", typ: u(undefined, a(r("Part"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "title", js: "title", typ: "" },
    ], false),
    "Parameter": o([
        { json: "class", js: "class", typ: u(undefined, "") },
        { json: "constraints", js: "constraints", typ: u(undefined, a(r("Constraint"))) },
        { json: "depends-on", js: "depends-on", typ: u(undefined, "") },
        { json: "guidelines", js: "guidelines", typ: u(undefined, a(r("Guideline"))) },
        { json: "id", js: "id", typ: "" },
        { json: "label", js: "label", typ: u(undefined, "") },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "select", js: "select", typ: u(undefined, r("Selection")) },
        { json: "usage", js: "usage", typ: u(undefined, "") },
        { json: "values", js: "values", typ: u(undefined, a("")) },
    ], false),
    "Constraint": o([
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "tests", js: "tests", typ: u(undefined, a(r("ConstraintTest"))) },
    ], false),
    "ConstraintTest": o([
        { json: "expression", js: "expression", typ: "" },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
    ], false),
    "Guideline": o([
        { json: "prose", js: "prose", typ: "" },
    ], false),
    "Selection": o([
        { json: "choice", js: "choice", typ: u(undefined, a("")) },
        { json: "how-many", js: "how-many", typ: u(undefined, r("ParameterCardinality")) },
    ], false),
    "ControlGroup": o([
        { json: "class", js: "class", typ: u(undefined, "") },
        { json: "controls", js: "controls", typ: u(undefined, a(r("Control"))) },
        { json: "groups", js: "groups", typ: u(undefined, a(r("ControlGroup"))) },
        { json: "id", js: "id", typ: u(undefined, "") },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "params", js: "params", typ: u(undefined, a(r("Parameter"))) },
        { json: "parts", js: "parts", typ: u(undefined, a(r("Part"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "title", js: "title", typ: "" },
    ], false),
    "ComponentDefinition": o([
        { json: "back-matter", js: "back-matter", typ: u(undefined, r("BackMatter")) },
        { json: "capabilities", js: "capabilities", typ: u(undefined, a(r("Capability"))) },
        { json: "components", js: "components", typ: u(undefined, a(r("ComponentDefinitionComponent"))) },
        { json: "import-component-definitions", js: "import-component-definitions", typ: u(undefined, a(r("ImportComponentDefinition"))) },
        { json: "metadata", js: "metadata", typ: r("PublicationMetadata") },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "Capability": o([
        { json: "control-implementations", js: "control-implementations", typ: u(undefined, a(r("ControlImplementationSet"))) },
        { json: "description", js: "description", typ: "" },
        { json: "incorporates-components", js: "incorporates-components", typ: u(undefined, a(r("IncorporatesComponent"))) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "name", js: "name", typ: "" },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "ControlImplementationSet": o([
        { json: "description", js: "description", typ: "" },
        { json: "implemented-requirements", js: "implemented-requirements", typ: a(r("ImplementedRequirementElement")) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "set-parameters", js: "set-parameters", typ: u(undefined, a(r("SetParameterValue"))) },
        { json: "source", js: "source", typ: "" },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "ImplementedRequirementElement": o([
        { json: "control-id", js: "control-id", typ: "" },
        { json: "description", js: "description", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-roles", js: "responsible-roles", typ: u(undefined, a(r("ResponsibleRole"))) },
        { json: "set-parameters", js: "set-parameters", typ: u(undefined, a(r("SetParameterValue"))) },
        { json: "statements", js: "statements", typ: u(undefined, a(r("ControlStatementImplementation"))) },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "SetParameterValue": o([
        { json: "param-id", js: "param-id", typ: "" },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "values", js: "values", typ: a("") },
    ], false),
    "ControlStatementImplementation": o([
        { json: "description", js: "description", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-roles", js: "responsible-roles", typ: u(undefined, a(r("ResponsibleRole"))) },
        { json: "statement-id", js: "statement-id", typ: "" },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "IncorporatesComponent": o([
        { json: "component-uuid", js: "component-uuid", typ: "" },
        { json: "description", js: "description", typ: "" },
    ], false),
    "ComponentDefinitionComponent": o([
        { json: "control-implementations", js: "control-implementations", typ: u(undefined, a(r("ControlImplementationSet"))) },
        { json: "description", js: "description", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "protocols", js: "protocols", typ: u(undefined, a(r("ServiceProtocolInformation"))) },
        { json: "purpose", js: "purpose", typ: u(undefined, "") },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-roles", js: "responsible-roles", typ: u(undefined, a(r("ResponsibleRole"))) },
        { json: "title", js: "title", typ: "" },
        { json: "type", js: "type", typ: "" },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "ImportComponentDefinition": o([
        { json: "href", js: "href", typ: "" },
    ], false),
    "PlanOfActionAndMilestonesPOAM": o([
        { json: "back-matter", js: "back-matter", typ: u(undefined, r("BackMatter")) },
        { json: "import-ssp", js: "import-ssp", typ: u(undefined, r("ImportSystemSecurityPlan")) },
        { json: "local-definitions", js: "local-definitions", typ: u(undefined, r("PlanOfActionAndMilestonesLocalDefinitions")) },
        { json: "metadata", js: "metadata", typ: r("PublicationMetadata") },
        { json: "observations", js: "observations", typ: u(undefined, a(r("Observation"))) },
        { json: "poam-items", js: "poam-items", typ: a(r("POAMItem")) },
        { json: "risks", js: "risks", typ: u(undefined, a(r("IdentifiedRisk"))) },
        { json: "system-id", js: "system-id", typ: u(undefined, r("SystemIdentification")) },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "PlanOfActionAndMilestonesLocalDefinitions": o([
        { json: "components", js: "components", typ: u(undefined, a(r("AssessmentAssetsComponent"))) },
        { json: "inventory-items", js: "inventory-items", typ: u(undefined, a(r("InventoryItem"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
    ], false),
    "POAMItem": o([
        { json: "description", js: "description", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "origins", js: "origins", typ: u(undefined, a(r("PoamItemOrigin"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "related-observations", js: "related-observations", typ: u(undefined, a(r("PoamItemRelatedObservation"))) },
        { json: "related-risks", js: "related-risks", typ: u(undefined, a(r("PoamItemRelatedRisk"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "title", js: "title", typ: "" },
        { json: "uuid", js: "uuid", typ: u(undefined, "") },
    ], false),
    "PoamItemOrigin": o([
        { json: "actors", js: "actors", typ: a(r("OriginatingActor")) },
    ], false),
    "PoamItemRelatedObservation": o([
        { json: "observation-uuid", js: "observation-uuid", typ: "" },
    ], false),
    "PoamItemRelatedRisk": o([
        { json: "risk-uuid", js: "risk-uuid", typ: "" },
    ], false),
    "SystemIdentification": o([
        { json: "id", js: "id", typ: "" },
        { json: "identifier-type", js: "identifier-type", typ: u(undefined, "") },
    ], false),
    "Profile": o([
        { json: "back-matter", js: "back-matter", typ: u(undefined, r("BackMatter")) },
        { json: "imports", js: "imports", typ: a(r("ImportResource")) },
        { json: "merge", js: "merge", typ: u(undefined, r("MergeControls")) },
        { json: "metadata", js: "metadata", typ: r("PublicationMetadata") },
        { json: "modify", js: "modify", typ: u(undefined, r("ModifyControls")) },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "ImportResource": o([
        { json: "exclude-controls", js: "exclude-controls", typ: u(undefined, a(r("Call"))) },
        { json: "href", js: "href", typ: "" },
        { json: "include-all", js: "include-all", typ: u(undefined, r("IncludeAll")) },
        { json: "include-controls", js: "include-controls", typ: u(undefined, a(r("Call"))) },
    ], false),
    "Call": o([
        { json: "matching", js: "matching", typ: u(undefined, a(r("MatchControlsByPattern"))) },
        { json: "with-child-controls", js: "with-child-controls", typ: u(undefined, r("IncludeContainedControlsWithControl")) },
        { json: "with-ids", js: "with-ids", typ: u(undefined, a("")) },
    ], false),
    "MatchControlsByPattern": o([
        { json: "pattern", js: "pattern", typ: u(undefined, "") },
    ], false),
    "MergeControls": o([
        { json: "as-is", js: "as-is", typ: u(undefined, true) },
        { json: "combine", js: "combine", typ: u(undefined, r("CombinationRule")) },
        { json: "custom", js: "custom", typ: u(undefined, r("CustomGrouping")) },
        { json: "flat", js: "flat", typ: u(undefined, r("Flat")) },
    ], false),
    "CombinationRule": o([
        { json: "method", js: "method", typ: u(undefined, r("CombinationMethod")) },
    ], false),
    "CustomGrouping": o([
        { json: "groups", js: "groups", typ: u(undefined, a(r("CustomGroup"))) },
        { json: "insert-controls", js: "insert-controls", typ: u(undefined, a(r("SelectControls"))) },
    ], false),
    "CustomGroup": o([
        { json: "class", js: "class", typ: u(undefined, "") },
        { json: "groups", js: "groups", typ: u(undefined, a(r("CustomGroup"))) },
        { json: "id", js: "id", typ: u(undefined, "") },
        { json: "insert-controls", js: "insert-controls", typ: u(undefined, a(r("SelectControls"))) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "params", js: "params", typ: u(undefined, a(r("Parameter"))) },
        { json: "parts", js: "parts", typ: u(undefined, a(r("Part"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "title", js: "title", typ: "" },
    ], false),
    "SelectControls": o([
        { json: "exclude-controls", js: "exclude-controls", typ: u(undefined, a(r("Call"))) },
        { json: "include-all", js: "include-all", typ: u(undefined, r("IncludeAll")) },
        { json: "include-controls", js: "include-controls", typ: u(undefined, a(r("Call"))) },
        { json: "order", js: "order", typ: u(undefined, r("Order")) },
    ], false),
    "Flat": o([], false),
    "ModifyControls": o([
        { json: "alters", js: "alters", typ: u(undefined, a(r("Alteration"))) },
        { json: "set-parameters", js: "set-parameters", typ: u(undefined, a(r("ParameterSetting"))) },
    ], false),
    "Alteration": o([
        { json: "adds", js: "adds", typ: u(undefined, a(r("Addition"))) },
        { json: "control-id", js: "control-id", typ: "" },
        { json: "removes", js: "removes", typ: u(undefined, a(r("Removal"))) },
    ], false),
    "Addition": o([
        { json: "by-id", js: "by-id", typ: u(undefined, "") },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "params", js: "params", typ: u(undefined, a(r("Parameter"))) },
        { json: "parts", js: "parts", typ: u(undefined, a(r("Part"))) },
        { json: "position", js: "position", typ: u(undefined, r("Position")) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "title", js: "title", typ: u(undefined, "") },
    ], false),
    "Removal": o([
        { json: "by-class", js: "by-class", typ: u(undefined, "") },
        { json: "by-id", js: "by-id", typ: u(undefined, "") },
        { json: "by-item-name", js: "by-item-name", typ: u(undefined, "") },
        { json: "by-name", js: "by-name", typ: u(undefined, "") },
        { json: "by-ns", js: "by-ns", typ: u(undefined, "") },
    ], false),
    "ParameterSetting": o([
        { json: "class", js: "class", typ: u(undefined, "") },
        { json: "constraints", js: "constraints", typ: u(undefined, a(r("Constraint"))) },
        { json: "depends-on", js: "depends-on", typ: u(undefined, "") },
        { json: "guidelines", js: "guidelines", typ: u(undefined, a(r("Guideline"))) },
        { json: "label", js: "label", typ: u(undefined, "") },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "param-id", js: "param-id", typ: "" },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "select", js: "select", typ: u(undefined, r("Selection")) },
        { json: "usage", js: "usage", typ: u(undefined, "") },
        { json: "values", js: "values", typ: u(undefined, a("")) },
    ], false),
    "SystemSecurityPlanSSP": o([
        { json: "back-matter", js: "back-matter", typ: u(undefined, r("BackMatter")) },
        { json: "control-implementation", js: "control-implementation", typ: r("ControlImplementationClass") },
        { json: "import-profile", js: "import-profile", typ: r("ImportProfile") },
        { json: "metadata", js: "metadata", typ: r("PublicationMetadata") },
        { json: "system-characteristics", js: "system-characteristics", typ: r("SystemCharacteristics") },
        { json: "system-implementation", js: "system-implementation", typ: r("SystemImplementation") },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "ControlImplementationClass": o([
        { json: "description", js: "description", typ: "" },
        { json: "implemented-requirements", js: "implemented-requirements", typ: a(r("ControlBasedRequirement")) },
        { json: "set-parameters", js: "set-parameters", typ: u(undefined, a(r("SetParameterValue"))) },
    ], false),
    "ControlBasedRequirement": o([
        { json: "by-components", js: "by-components", typ: u(undefined, a(r("ComponentControlImplementation"))) },
        { json: "control-id", js: "control-id", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-roles", js: "responsible-roles", typ: u(undefined, a(r("ResponsibleRole"))) },
        { json: "set-parameters", js: "set-parameters", typ: u(undefined, a(r("SetParameterValue"))) },
        { json: "statements", js: "statements", typ: u(undefined, a(r("SpecificControlStatement"))) },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "ComponentControlImplementation": o([
        { json: "component-uuid", js: "component-uuid", typ: "" },
        { json: "description", js: "description", typ: "" },
        { json: "export", js: "export", typ: u(undefined, r("Export")) },
        { json: "implementation-status", js: "implementation-status", typ: u(undefined, r("ImplementationStatus")) },
        { json: "inherited", js: "inherited", typ: u(undefined, a(r("InheritedControlImplementation"))) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-roles", js: "responsible-roles", typ: u(undefined, a(r("ResponsibleRole"))) },
        { json: "satisfied", js: "satisfied", typ: u(undefined, a(r("SatisfiedControlImplementationResponsibility"))) },
        { json: "set-parameters", js: "set-parameters", typ: u(undefined, a(r("SetParameterValue"))) },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "Export": o([
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "provided", js: "provided", typ: u(undefined, a(r("ProvidedControlImplementation"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsibilities", js: "responsibilities", typ: u(undefined, a(r("ControlImplementationResponsibility"))) },
    ], false),
    "ProvidedControlImplementation": o([
        { json: "description", js: "description", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-roles", js: "responsible-roles", typ: u(undefined, a(r("ResponsibleRole"))) },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "ControlImplementationResponsibility": o([
        { json: "description", js: "description", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "provided-uuid", js: "provided-uuid", typ: u(undefined, "") },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-roles", js: "responsible-roles", typ: u(undefined, a(r("ResponsibleRole"))) },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "InheritedControlImplementation": o([
        { json: "description", js: "description", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "provided-uuid", js: "provided-uuid", typ: u(undefined, "") },
        { json: "responsible-roles", js: "responsible-roles", typ: u(undefined, a(r("ResponsibleRole"))) },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "SatisfiedControlImplementationResponsibility": o([
        { json: "description", js: "description", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsibility-uuid", js: "responsibility-uuid", typ: u(undefined, "") },
        { json: "responsible-roles", js: "responsible-roles", typ: u(undefined, a(r("ResponsibleRole"))) },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "SpecificControlStatement": o([
        { json: "by-components", js: "by-components", typ: u(undefined, a(r("ComponentControlImplementation"))) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-roles", js: "responsible-roles", typ: u(undefined, a(r("ResponsibleRole"))) },
        { json: "statement-id", js: "statement-id", typ: "" },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "ImportProfile": o([
        { json: "href", js: "href", typ: "" },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
    ], false),
    "SystemCharacteristics": o([
        { json: "authorization-boundary", js: "authorization-boundary", typ: r("AuthorizationBoundary") },
        { json: "data-flow", js: "data-flow", typ: u(undefined, r("DataFlow")) },
        { json: "date-authorized", js: "date-authorized", typ: u(undefined, "") },
        { json: "description", js: "description", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "network-architecture", js: "network-architecture", typ: u(undefined, r("NetworkArchitecture")) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "responsible-parties", js: "responsible-parties", typ: u(undefined, a(r("ResponsibleParty"))) },
        { json: "security-impact-level", js: "security-impact-level", typ: r("SecurityImpactLevel") },
        { json: "security-sensitivity-level", js: "security-sensitivity-level", typ: "" },
        { json: "status", js: "status", typ: r("SystemCharacteristicsStatus") },
        { json: "system-ids", js: "system-ids", typ: a(r("SystemIdentification")) },
        { json: "system-information", js: "system-information", typ: r("SystemInformation") },
        { json: "system-name", js: "system-name", typ: "" },
        { json: "system-name-short", js: "system-name-short", typ: u(undefined, "") },
    ], false),
    "AuthorizationBoundary": o([
        { json: "description", js: "description", typ: "" },
        { json: "diagrams", js: "diagrams", typ: u(undefined, a(r("Diagram"))) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
    ], false),
    "Diagram": o([
        { json: "caption", js: "caption", typ: u(undefined, "") },
        { json: "description", js: "description", typ: u(undefined, "") },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "DataFlow": o([
        { json: "description", js: "description", typ: "" },
        { json: "diagrams", js: "diagrams", typ: u(undefined, a(r("Diagram"))) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
    ], false),
    "NetworkArchitecture": o([
        { json: "description", js: "description", typ: "" },
        { json: "diagrams", js: "diagrams", typ: u(undefined, a(r("Diagram"))) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
    ], false),
    "SecurityImpactLevel": o([
        { json: "security-objective-availability", js: "security-objective-availability", typ: "" },
        { json: "security-objective-confidentiality", js: "security-objective-confidentiality", typ: "" },
        { json: "security-objective-integrity", js: "security-objective-integrity", typ: "" },
    ], false),
    "SystemCharacteristicsStatus": o([
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "state", js: "state", typ: r("FluffyState") },
    ], false),
    "SystemInformation": o([
        { json: "information-types", js: "information-types", typ: a(r("InformationType")) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
    ], false),
    "InformationType": o([
        { json: "availability-impact", js: "availability-impact", typ: r("AvailabilityImpactLevel") },
        { json: "categorizations", js: "categorizations", typ: u(undefined, a(r("InformationTypeCategorization"))) },
        { json: "confidentiality-impact", js: "confidentiality-impact", typ: r("ConfidentialityImpactLevel") },
        { json: "description", js: "description", typ: "" },
        { json: "integrity-impact", js: "integrity-impact", typ: r("IntegrityImpactLevel") },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "title", js: "title", typ: "" },
        { json: "uuid", js: "uuid", typ: u(undefined, "") },
    ], false),
    "AvailabilityImpactLevel": o([
        { json: "adjustment-justification", js: "adjustment-justification", typ: u(undefined, "") },
        { json: "base", js: "base", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "selected", js: "selected", typ: u(undefined, "") },
    ], false),
    "InformationTypeCategorization": o([
        { json: "information-type-ids", js: "information-type-ids", typ: u(undefined, a("")) },
        { json: "system", js: "system", typ: "" },
    ], false),
    "ConfidentialityImpactLevel": o([
        { json: "adjustment-justification", js: "adjustment-justification", typ: u(undefined, "") },
        { json: "base", js: "base", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "selected", js: "selected", typ: u(undefined, "") },
    ], false),
    "IntegrityImpactLevel": o([
        { json: "adjustment-justification", js: "adjustment-justification", typ: u(undefined, "") },
        { json: "base", js: "base", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "selected", js: "selected", typ: u(undefined, "") },
    ], false),
    "SystemImplementation": o([
        { json: "components", js: "components", typ: a(r("AssessmentAssetsComponent")) },
        { json: "inventory-items", js: "inventory-items", typ: u(undefined, a(r("InventoryItem"))) },
        { json: "leveraged-authorizations", js: "leveraged-authorizations", typ: u(undefined, a(r("LeveragedAuthorization"))) },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "users", js: "users", typ: a(r("SystemUser")) },
    ], false),
    "LeveragedAuthorization": o([
        { json: "date-authorized", js: "date-authorized", typ: "" },
        { json: "links", js: "links", typ: u(undefined, a(r("Link"))) },
        { json: "party-uuid", js: "party-uuid", typ: "" },
        { json: "props", js: "props", typ: u(undefined, a(r("Property"))) },
        { json: "remarks", js: "remarks", typ: u(undefined, "") },
        { json: "title", js: "title", typ: "" },
        { json: "uuid", js: "uuid", typ: "" },
    ], false),
    "Transport": [
        "TCP",
        "UDP",
    ],
    "PurpleState": [
        "disposition",
        "operational",
        "other",
        "under-development",
    ],
    "PartyType": [
        "organization",
        "person",
    ],
    "TimeUnit": [
        "days",
        "hours",
        "minutes",
        "months",
        "seconds",
        "years",
    ],
    "ActorType": [
        "assessment-platform",
        "party",
        "tool",
    ],
    "ObjectiveStatusState": [
        "not-satisfied",
        "satisfied",
    ],
    "FindingTargetType": [
        "objective-id",
        "statement-id",
    ],
    "ParameterCardinality": [
        "one",
        "one-or-more",
    ],
    "IncludeContainedControlsWithControl": [
        "no",
        "yes",
    ],
    "CombinationMethod": [
        "keep",
        "merge",
        "use-first",
    ],
    "Order": [
        "ascending",
        "descending",
        "keep",
    ],
    "Position": [
        "after",
        "before",
        "ending",
        "starting",
    ],
    "FluffyState": [
        "disposition",
        "operational",
        "other",
        "under-development",
        "under-major-modification",
    ],
};
